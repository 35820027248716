<template>
  <v-container class="not-extended">
    <v-data-table
      v-bind="{ ...bind.table, ...tableBind }"
      v-on="tableOn"
      @click:row="handlerAction"
      class="is-selectable"
    >
      <template #top>
        <div class="d-flex pa-3">
          <v-text-field
            v-model="search"
            v-bind="bind.search"
            v-on="searchOn"
            @keyup.enter="filter"
            placeholder="Escribe el nombre comercial del producto..."
          ></v-text-field>
          <v-divider v-bind="bind.divider"></v-divider>

          <v-spacer></v-spacer>
          <v-btn v-bind="bind.refresh" v-on="on.refresh">
            <v-icon>{{ icons.refresh }}</v-icon>
          </v-btn>
          <v-btn v-bind="bind.new" :to="{ name: 'requests' }">
            Nuevo producto <v-icon right>mdi-archive-plus</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:[`item.estado_descripcion`]="{ item }">
        <v-chip :color="status[item.estado].color" outlined label>
          <!-- @click="item.estado = !item.estado" -->
          <v-icon left>
            {{
              status[item.estado]
                ? status[item.estado].icon
                : status.default.icon
            }}
          </v-icon>
          {{ item.estado_descripcion || item.estado || "" }}
        </v-chip>
      </template>
    </v-data-table>

    <ProductActionModal
      v-model="dialog"
      :title="`Gestión del producto ${
        selected ? '- Solicitud nro ' + selected.id_solicitud : ''
      }`"
      :productTitle="
        selected ? selected.definicion_basica.nombre_comercial : ''
      "
      :companyTitle="
        selected ? selected.definicion_basica.compania.descripcion : ''
      "
      @close="dialog = false"
      @action="handlerSelectAction"
      :datamodal="selected"
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ProductActionModal from "@/components/Modal/Modal-ProductAction.vue";
import { productStatus } from "@/store/resources/status.js";

import PaginationMixin from "@/Mixins/PaginationMixin.js";
import PageMixin from "@/Mixins/SetPageMixin.js";

export default {
  name: "ProductsPage",
  components: {
    ProductActionModal,
  },
  mixins: [PageMixin, PaginationMixin],
  data() {
    return {
      headers: [
        {
          text: "Nombre Comercial",
          value: "definicion_basica.nombre_comercial",
          align: "left",
          sortable: false,
        },
        {
          text: "Compañia",
          value: "definicion_basica.compania.descripcion",
          align: "left",
          sortable: false,
        },
        {
          text: "N° de Solicitud",
          value: "id_solicitud",
          align: "center",
          sortable: false,
        },
        {
          text: "Estado",
          value: "estado_descripcion",
          align: "right",
          sortable: false,
        },
      ],
      Estado: {
        label: "Estado",
        answers: [
          "Todos",
          "Solicitados",
          "En configuración",
          "En certificación",
          "Activo",
          "Rechazado",
        ],
        selected: "",
      },
    };
  },
  // ------------------------------METHODS
  methods: {
    ...mapActions("NewProduct", ["getProducts"]),

    handlerAction(item) {
      this.dialog = true;
      this.selected = item;
      this.$store.commit("Prodcut", item);
    },

    handlerSelectAction(item) {
      if (item && item.code) {
        switch (item.code) {
          case "update":
            this.$router.push({ name: "EditProduct" });
            break;

          default:
            break;
        }
      }
    },

    async OpenUrlDocument(item) {
      let url = await this.openUrlDocument(item.url);
      window.open(url, "Documento", null);
    },

    async setup() {
      this.loading = true;
      await this.getProducts({ ...this.pagination, filter: this.search })
        .then((response) => {
          if (response.pagination) {
            this.setPaginationFromResponse(response.pagination);
          }
        })
        .finally(() => (this.loading = false));
    },

    setPaginationFromResponse(data) {
      this.pagination.current = data.pagina;
      this.pagination.limit = data.limite;
      this.pagination.pages = data.paginas;
      this.pagination.objects = data.total;
    },
  },
  // ------------------------------COMPUTED
  computed: {
    ...mapGetters("NewProduct", ["productos"]),

    items() {
      return this.productos;
    },
    status() {
      return productStatus;
    },
  },
};
</script>
