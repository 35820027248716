<template>
  <v-dialog :value="value" @click:outside="$emit('close')" max-width="600">
    <v-card>
      <v-card-title class="primary white--text">
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn color="white" fab x-small text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-3 grey lighten-3">
        <h2>{{ productTitle }}</h2>
        <p class="mb-0 caption">{{ companyTitle }}</p>
      </v-card-text>
      <v-divider class="mb-1"></v-divider>
      <v-row no-gutters class="pa-3 justify-center">
        <v-col
          v-for="item in items"
          :key="item.id"
          cols="12"
          md="6"
          class="pa-1"
        >
          <v-card
            class="mx-auto"
            :color="item.color"
            dark
            @click="handlerSelectItem(item)"
          >
            <v-card-title>
              <v-icon large left>
                {{ item.icon }}
              </v-icon>
            </v-card-title>

            <v-card-text class="text-h5 font-weight-bold">
              {{ item.text }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ProductActionModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
      description: "Titulo de la modal",
    },
    productTitle: {
      type: String,
      default: "",
      description: "Nombre del producto",
    },
    companyTitle: {
      type: String,
      default: "",
      description: "Nombre de la compania del producto",
    },
  },
  data: () => ({
    items: [
      {
        id: 1,
        text: "Editar Producto",
        icon: "mdi-archive-edit",
        color: "info lighten-1",
        code: "update",
      },
    ],
  }),
  methods: {
    closebtn() {
      this.$emit("close");
    },
    handlerSelectItem(item) {
      this.$emit("action", item);
    },
  },
};
</script>
