export const productStatus = {
  SOL: {
    icon: "mdi-receipt-text-check",
    color: "#55DF5F",
  },
  REC: {
    icon: "mdi-receipt-text-remove",
    color: "#EA2F2F",
  },
  ACT: {
    icon: "mdi-circle",
    color: "#ACEA2F",
  },
  CON: {
    icon: "mdi-cog",
    color: "#228E75",
  },
  CER: {
    icon: "mdi-certificate",
    color: "#228E75",
  },
  default: {
    icon: "mdi-receipt-text-outline",
    color: "grey lighten-2",
  },
};
